@media (min-width: 1200px) {

	.inforestaurante .infoboxes{
		padding: 0 20px;
		h4{
			font-size: 18px;
		}
		p{
			font-size: 14px;
		}
	}
	.ingredientes{
		&.ramo{
			display: block; 
			margin-top: -170px;
			left:-20px;
		}
	}
	.modal-dialog{
		margin: 62px auto;
	}
}