.section-quantity__input {
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ccc;
  padding: 6px 0;
  font-size: 14px;
  line-height: 1.52857;
  border-radius: 4px;
  color: #333;
}
