
@import "bootstrap-compass";    // Import Bootstrap Compass integration
@import "bootstrap-variables";  // Import custom Bootstrap variables
@import "bootstrap";            // Import Bootstrap for Sass


//  COMPASS MAGIC FOR SPRITES
// @import "compass/utilities/sprites";
// @import "../images/social/*.png";
// @include all-social-sprites;
//----------------------------------------------
//  VARIABLES COLOR

	$light-gray: #DADADA;		// cizento claro
	//$btn-primary-color;			// branco
	//$btn-primary-bg;			// laranja escuro
	//$link-color;				// castanho
	$shadow-color: #555;		           // #555
	$orange40: lighten(orange,40%);	// laranja pouco claro 
	$orange45: lighten(orange,45%);	// laranja claro 

//----------------------------------------------
//  VARIABLES FONTS

	@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
	body{	font-weight: 400;}
	.bold{	font-weight: 700;}
	.semi{	font-weight: 600;}
	

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

img{
	max-width: 100%;
}
.hide-line{
	border-color:transparent;
}
a:hover,a:focus{
	text-decoration: none;	
	color: $btn-primary-bg;
	@include transition(all .25s ease-in-out);
	outline: 0; // Por causa do firefox.
}
.selectize-input{
	border-radius: 6px;
	height: 40px;
	line-height: 40px;
	padding: 0 8px;		
}
.first-container{
	margin-top: 40px;
}
//  HEADER
.main-header{	
	background: $btn-primary-color;
	width: 100%;
	box-shadow:0 0 10px $light-gray;
}
.background-wrap{
	background-image: url('../images/search-wrap.jpg');
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
	display: block;
	height: 500px;
	width: 100%;
	margin:0;
	padding:0;
}
// MENU TOP BAR
.navbar-toggle{
	margin-top: 30px;
	.menu-button-toggle{
		@extend .bold;
		color: $link-color;
	}
}
.navbar{
	background: $btn-primary-color;
	margin-bottom: 0;
}
.navbar-brand{
	float: none;
	padding:0px;
	display: block;
	height: auto;
	img{
		max-width: 100%;
	}
}
.navbar-header{
	background: $btn-primary-color;
}
.navbar-collapse{
	margin-top: 40px;
	background: $btn-primary-color;
}
.navbar-default{
	border: none;
}
.navbar-default .navbar-right{
	> li {
		a {
			padding: 14px 15px;
			@extend .bold;
			color: $link-color;
			text-transform: uppercase;
			@include transition-duration(.25s);
			&:hover,&:focus{
				color: orange;
			}
		}
	}
}
.languages{
	.dropdown-toggle{
		padding:10px 15px 8px 15px !important;
	}
	.caret{
		margin-bottom:12px;
	}
	.flag{
		border:1px transparent solid;
		background-repeat: no-repeat;
		display: inline-block;
		width: 38px;
		height: 26px;
		background-position: 0px -7px;
	}
	.dropdown-menu{
		min-width: 94px;
		.active{
			display: none;
		}
	}
	.dropdown-menu li a{
		height: 38px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.pt-flag{
		background-image: url('../images/pt.png');
	}
	.en-flag{
		background-image: url('../images/en.png');
	}
}
//SLIDESHOW AREA
.main-section{
	background-color: transparent;
	background-image: url('../images/search-wrap.jpg');
	background-repeat: no-repeat;
	background-position: center 0;
	background-size: cover;
	position: relative;
	padding: 48px 0px;
	color: $btn-primary-color;
}
.search-form{
	width: 100%;
	.form-group{
		width: 43%;
		height: 40px;
	}
	.form-control{
		width: 100%;
		border: none;
	}
	button[type="submit"]{
		width: 12%;
		height: 40px;
		display: inline-block;
		&:hover{
			background-color: $btn-primary-bg;
		}
		img{
			width: 24px;
		}
	}
}
.btn-restaurants{
	border:1px #FFF solid;
	color: #FFF;
	background-color: rgba(0,0,0,0.5);
	opacity: 0.5;
	&:hover{
		opacity: 1;
		color: #CCC;
		background-color: rgba(0,0,0,0.2);
	}
}
.current_zone{
	position: relative;
	padding-top: 13px;
	white-space: nowrap;
	a{
		padding:0px !important;
		font-weight: normal !important;
		font-size: 12px !important;
		display: inline-block !important;
	}
}
.tip{
	position: absolute;
	top: 100%;
	left: 0px;
	margin:0px;
	margin-top: 5px;
	padding:8px;
	cursor: pointer;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	&:before{
		content:"";
		position: absolute;
		top:-8px;
		left: 50%;
		margin-left: -4px;
		display: block;
		border-width: 8px;
		border-style: solid;
		border-color: transparent;
		border-top-width: 0px;
		border-bottom-color: inherit;
	}
}
//HIGHLIGHTS AREA
.highlights-wrap{
	padding: 58px 0;
	overflow: hidden;
	background-image: url('../images/bgbranco.png');
	.home-highlight{
		text-align: center;
		img{
			width: 100%;
		}
	}
	.block-wrap{
		margin: -15px -25px;
		padding: 35px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		&.one{
			background-image: url('../images/bloco1.png');
		}
		&.two{
			background-image: url('../images/bloco2.png');
		}
		&.three{
			background-image: url('../images/bloco3.png');
		}
	}
	.block{
		padding:12px;
		background-color: $btn-primary-color;
		background-color: rgba(255,255,255,0.9);
		border: 1px solid gray;
		text-align: center;
		h1,h2,h3,h4,h5,h6{
			text-transform: uppercase;
			color: $link-color;
			font-size: 1em;
			@extend .semi;
		}
		p{
			margin-top: 15px;
		}
		.btn{
			width:100%;
		}
	}
}

//blocos da pagina restaurantes
.rest{
	padding-bottom: 15px;
	border-bottom: 1px #ddd solid;
	margin-bottom: 15px;
	.thumbnail{
		margin-bottom: 8px;
		min-height: 100px;
		display: block;
	}
	.rest-body{
		height:108px;
		margin-bottom:8px;
		overflow: hidden;
	}
	&.closed{
	}
}
.rest-info{
	margin: 21px 0px;
	border-top: 1px #f5f5f5 solid;
	border-bottom: 1px #f5f5f5 solid;
	color: #E26B14;
	padding-top: 10px;
	padding-bottom: 10px;
	b{
		color: #555;
		font-weight: normal;
	}
}
.block.restaurants{
	border: none;
	&:first-child{
		margin-top: 20px;
	}
	position: relative;
	margin: 0 0 20px 0;
	&.bordered{
		border: 5px solid $light-gray;
		border-radius: 5px;
		box-shadow: 0px 4px 5px -2px $shadow-color;
	}
	.panel-heading{
		height: auto;
		margin-bottom: 20px;
		padding: 10px;
		border-radius: 0px;
		h4{
			color: $btn-primary-bg;
			font-size: 20px;
			text-transform: capitalize;
		}
		img{
			width: 100%;
		}
		a.btn{
			border-radius: 0;
		}	
	}	
}
.search-result{
	line-height: 40px;
}
.block.restaurants{
	img{
		border: 2px solid $link-color;
		border-radius: 3px;
	}
}
.links{
	float: right;
	a{
		margin: 0 10px;
	}
}
.advertisings{
	img{
		width: 100%;
	}
}
//HOW IT WORKS AREA
.works-wrap{
	padding: 20px 0 50px 0;
	background-image: url('../images/wood.jpg');
	background-attachment: fixed;
	color: $btn-primary-color;
	position: relative;
	&:before{
		position: absolute;
		content: '';
		top:-20px;
		left:-10px;
		width: 165px;
		height: 174px;
		background-image: url('../images/alho.png');
		background-repeat: no-repeat;
	}
	&:after{
		position: absolute;
		content: '';
		top:0px;
		right:0px;
		width: 186px;
		height: 263px;
		background-image: url('../images/colher.png');
		background-repeat: no-repeat;
	}
}
.step{
	text-align: center;
	position: relative;
	p{
		margin: auto 20px;
	}
	&:after{
		position: absolute;
		content: '';
		top:70px;
		left:100%;
		margin-left:-30px;
		width: 113px;
		height: 86px;
		background-image: url('../images/arrowdown.png');
		background-repeat: no-repeat;
	}
}
.step.down{
	margin-top: 110px;
	&:after{
		position: absolute;
		content: '';
		top:-98px;
		left:100%;
		margin-left:-30px;
		width: 113px;
		height: 86px;
		background-image: url('../images/arrowup.png');
		background-repeat: no-repeat;
	}
}
.step.last{
	&:after{
		display: none !important;
	}
}
.arrow{
	position: absolute;
	margin-left: 70px;
	&.down{
		top: 50px;
	}
	&.up{
		margin-left: 50px;
		margin-top: -100px;
	}
}

.partners-wrap{
	padding: 50px 0px;
	h2{
		font-size: 40px;
		&:after{
			content: " ";
			display: block;
			height: 3px;
			width: 60px;
			background-color: $shadow-color;
			margin: 20px auto 40px 0;
		}
	}
	position: relative;
	&:before{
		position: absolute;
		content: '';
		bottom:-100px;
		left:-10px;
		width: 162px;
		height: 249px;
		background-image: url('../images/ramo.png');
		background-repeat: no-repeat;
		z-index: 5;
	}
	&:after{
		position: absolute;
		content: '';
		top:-50px;
		right:0px;
		width: 194px;
		height: 217px;
		background-image: url('../images/louro.png');
		background-repeat: no-repeat;
		z-index: 5;
	}
}
.social-wrap{
	padding: 80px 0;
	background-color: $btn-primary-bg;
	p{
		color: $btn-primary-color;
		font-size: 2em;
	}
}
.icon-thumbnail{
	display: block;
	color: #FFF;
	padding-top: 100px;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center top;
	font-size: 1.1em;
	opacity: 0.7;
	margin-bottom: 40px;
	&.fb{
		background-image: url(../images/fb_icon.png);
	}
	&.tw{
		background-image: url(../images/twitter_icon.png);
	}
	&.pro{
		background-image: url(../images/promo_icon.png);
	}
	&:hover{
		color: #FFF;
		opacity: 1;
		&.fb{
			background-image: url(../images/fb_icon_hover.png);
		}
		&.tw{
			background-image: url(../images/twitter_icon_hover.png);
		}
		&.pro{
			background-image: url(../images/promo_icon_hover.png);
		}
	}
}
.main-footer{
	z-index: 10;
	background-image: #FFF;
	padding-bottom: 15px;
}
.footer-pic{
	 width: 100%;
}
// FOOTER
footer .logo{
	display: block;
	img{
		max-width: 100%;
		width: auto;
	}
}
.footer-nav{
	padding: 0;
	list-style: none;
	h5{
		font-size: 1.1em;
		margin:25px 0px 5px 0px;
		@extend .semi;
	}
	a{
		display: block;
	}

}
.social{
	margin: 0px;
	li{
		display: inline-block;
		a{
			padding: 5px;
			display: inline-block;
			i{
				padding: 0px;
				display: block;
				height: 20px;
				width: 20px;
			}
		}
	}
}

.dark-wood{
	padding: 50px 0 10px 0;
	background-image: url('../images/comobg.png');
	color: $btn-primary-color;
}

.ratings-list{
	margin-bottom:20px;
	.media{
		padding-bottom:12px;
	}
	.media-body{
		width: 100%;
		p.rating{
			margin:0px;
			color:#555;
			line-height: 16px;
			span{
				float:right;
				color:#333;
				position: relative;
				top:4px;
			}
		}
	}
}

.partner-schedules{
	border:1px #CCC solid;
	.nav-tabs > li > a{
		border:1px #ddd solid;
		border-radius: 0px;
	}
	.tab-content{
		padding:0px;
		border:0px !important;
	}
	.table{
		margin:0px;
	}
}

.partner-info{
	border:1px #CCC solid;
	.table{
		margin:0px;
	}
}

.small-ajax-loader{
	min-height: 80px;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../images/saln.gif);
}
#partner-map-canvas{
	height:300px;
}
.admin_tools{
	position: fixed;
	bottom:0px;
	right: 0px;
	width: 48px;
	height: 48px;
	z-index: 9999;
	padding: 8px 0px;
	background-color: #FFF;
	.container{
		display: none;
	}
	box-shadow: black 0px 0px 8px;
	.btn-lg{
		margin-right: 8px;
	}
	.popover{
		min-width: 280px;
	}
	.popover-title{
		font-weight: bold;
	}
	.admin-handle{
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: 48px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		font-size: 18px;
		color: #333;
		z-index: 20;
		cursor: pointer;
	}
	&.open{
		background-color: #D9EDF7;
		width: 100%;
		height: auto;
		left: 0px;
		.container{
			display: block;
		}
	}
}
body.admin .main-footer{
	margin-bottom: 60px;
}
.progress-status{
	font-size: 16px;
	color: #39281b;
}
.delivery-estimate-status {
	margin-top: 15px;
	width: 320px;
	text-align: left;
	display: inline-block;
	.icon {
		font-size: 36px;
		color: #ea7729;
		display: block;
		float:left;
		margin-right: 15px;
	}
	&.completed .icon{
		color: #5cb85c;
	}
	.delivery-text {
		display: block;
		float:left;
		font-size: 16px;
		line-height: 20px;
		padding: 4px 0px;
		strong {
			font-size: 18px;
		}
	}
	&.completed .delivery-text{
		padding-top: 14px;
		font-weight: bold;
		font-size: 22px;
	}
	&:after{
		content: "";
		display: table;
		clear: both;
	}
}
.order-progress{
	overflow: hidden;
	.progress{
		margin: 0px;
	}
	.legend{
		width: 100%;
		margin-top: -20px;
		.plabel{
			float:left;
			min-height: 64px;
			text-align: center;
			padding: 24px 8px 0px 8px;
		}
		strong{
			display: block;
		}
		.wait{width: 15%; border-right: 2px #39281B solid; color: #39281B;}
		.make{width: 50%; border-right: 2px $btn-primary-bg solid; color: $btn-primary-bg;}
		.ship{width: 25%; border-right: 2px #F0AD4E solid; color: #F0AD4E;}
		.done{width: 10%; color: #5CB85C;}
		.block-plabel{
			border-right: 0px !important;
			width: 100% !important;
			strong{
				display: inline;
			}
		}
	}
	.progress-bar-wait{
		background-color: #39281B;
	}
	.progress-bike{
		position: relative;
		width: 0;
		height: 38px;
		margin-bottom: -1px;
		-webkit-transition: width 1s ease-in-out;
		-moz-transition: width 1s ease-in-out;
		-ms-transition: width 1s ease-in-out;
		-o-transition: width 1s ease-in-out;
		transition: width 1s ease-in-out;
		span{
			position: absolute;
			width: 49px;
			height: 38px;
			display: block;
			top:0px;
			right:0px;
			background-image: url(../images/bike.png);
		}
		&.wait span{
			display: none;
		}
		&.make span{

		}
		&.ship span{

		}
		&.done span{
			display: none;
			right:4px;
		}
	}

}
.user_bottom_bar_spacer{
	height: 58px;
}
.user_bottom_bar{
	border-top: 2px $btn-primary-bg solid;
	background-color: #eee;
	text-align: center;
	padding: 10px 0px;
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	color: #333;
	-webkit-box-shadow: 0 0 12px 0 rgba(0,0,0,0.5);
	box-shadow: 0 0 12px 0 rgba(0,0,0,0.5);
}
.alert-down{
	padding-left: 86px;
	background-image: url(../images/arrow-down.png);
	background-position: 20px 50%;
	background-repeat: no-repeat;
}
.alert-compact {
	padding: 6px 10px;
	margin: 8px 0px;
}
.new-address-map {
	position: relative;
	.add-marker-to-map {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0,0,0,0.2);
		z-index: 32;
	}
	.add-marker-to-map-body{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 12px;
		width: 300px;
		color: #333;
		background-color: #FFF;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		margin-left: -150px;
		margin-top: -60px;

	}
	.google-map-container {
		width: 100%;
		height: 320px;
	}
	border: 1px transparent solid;
	overflow: hidden;
	border-radius: 4px;
	&.has-error {
		border-color: $brand-danger;
	}
}

.mb-0 {
	margin-bottom: 0px;
}
.alert-flat {
	border: 0px;
	border-radius: 0px;
}

.address-map {
	position: relative;
	.marker-to-map {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0,0,0,0.2);
		z-index: 32;
	}
	.marker-to-map-body{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 12px;
		width: 300px;
		color: #333;
		background-color: #FFF;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		margin-left: -150px;
		margin-top: -60px;

	}
	.google-map-container {
		width: 100%;
		height: 320px;
	}
	border: 1px transparent solid;
	overflow: hidden;
	border-radius: 4px;
	&.has-error {
		border-color: $brand-danger;
	}
}


.scheduling {
	padding: 10px;
	padding-top: 30px;
}

.btn-pre-order {
	color: #FFF;
	background-color: #582712;
}

.btn-pre-order:hover, 
.btn-pre-order:active,
.btn-pre-order:focus{
	color: #FFF;
}

.scheduling-form {
	h3 {
		padding: 12px 12px;
		padding-top: 20px;
		margin: 0px;
		font-size: 16px;
		font-weight: bold;
		background: #FFF;
		border-bottom: 1px #cac5ac solid;
	}

	.cancel-btn {
		margin-top: 15px;
	}
}
.scheduling-form-body {
	padding: 10px;
}

.login-or {
	clear: both;
	text-align: center;
	color: #777;
	font-size: 28px;
	padding: 10px 0px;
	margin-bottom: 10px;
}

.socialaccount_provider {
	display: inline-block;
	margin: 10px 0px;
	margin-right: 10px;
	img {
		max-width: 100%;
	}
	&.facebook {
		width: 290px;
	}
	&.google {
		width: 216px;
	}
	&:hover{
		opacity: 0.9;
	}
	&.disabled{
		opacity: 0.3;
	}
}


// styles of pages
@import "animations";
@import "menu"; 
@import "restaurants";
@import "section-quantity";

// media queries
@import "medium";
@import "small";
@import "large";