
.menu-hours{
	margin-top: 10px;
}
// SIDE MENU
.side-menu-wrap{
	#menu-index{
		//box-shadow: 0 2px 3px rgba($gray, 0.5));
		border:2px $btn-primary-bg solid;
		display: none;
		position: absolute;
		top: 0px;
		left: 14px;
		min-height:179px;
		min-width: 220px;
		right: 14px;
		z-index: 700;
		background-color: $btn-primary-color;
		list-style: none;
		padding: 0;
		white-space: nowrap;
		box-shadow: 0 3px 4px $shadow-color;

		.menu-index-title{
			background-color: #ddd;
			border-bottom:1px #CCC solid;
			color:#333;
			padding:8px 12px;
			padding-right:38px;
			cursor: pointer;
			a{
				color:#333;
				font-weight: bold;
			}
		}
		.menu-index-section{
			a{
				padding: 4px 12px;
				display: block;
				border-bottom:1px #CCC dotted;
				padding-left:28px;
			}
			a:last-child{
				border-bottom: none;
			}
		}
	}
	&.open{
		#menu-index{
			display: block;
		}
		.menu-btn{
			background-color: $btn-primary-bg;
			color: $btn-primary-color;
		}	
	}
	.menu-btn{
		display: block;
		border-bottom: none;
		padding: 0px 15px;
		line-height: 52px;
		text-transform: uppercase;
		background-color: #EC7823;
		color: #FFF;
		font-weight: bold;
	}

	.close-btn{
		position: absolute;
		top:5px;
		right:5px;
		display: block;
		width:28px;
		height:28px;
		line-height: 28px;
		text-align: center;
		font-weight: bold;
		background-color: #999;
		font-size:1.4em;
		color:#333;
	}
	.partner-text{
		display: none;
	}
	.menu-text{
		display: inline-block;
		line-height: 38px;
		color: #FFF;
		font-size: 16px;
	}
	.navbar-toggle{
		display: none;
		padding-right:0px;
		margin-top: 10px;
		.icon-bar{
			background-color: #FFF;
		}
	}
}
.fixed-pos{
	padding-top: 72px;
	.side-menu-wrap{
		position: fixed;
		top:0px;
		left:0px;
		right:0px;
		z-index: 999;
	}
	.partner-text{
		display: inline-block;
	}
	.menu-text{
		display: none;
	}
	.navbar-toggle{
		display: block;
	}
}
.inforestaurante{
	box-shadow: 0 10px 5px -6px $light-gray;
	margin-bottom: 50px;
	h2{
		text-transform: capitalize;
	}
	.rate{
		padding-top: 8px;
	}
	nav{
		border:1px #CCC solid;
		margin-top:18px;
		.btn{
			padding: 12px;
			border:1px transparent solid;
			border-left:1px #CCC solid;
			border-radius: 0;
			&.btn-back{
				border-left:1px transparent solid;
			}
		}
	}
}
.btn-back{
	border-left:1px transparent solid;
	color: #666;
	&:before{
		content:"";
		display:inline-block;
		position: relative;
		top:1px;
		left:-8px;
		border: 6px transparent solid;
		border-top: 6px transparent solid;
		border-bottom: 6px transparent solid;
		border-right: 8px #999 solid;
	}
	&:hover{
		color: $btn-primary-bg;
	}
}
.infoboxes{
	float: left;
	padding: 0 18px;
	border-right: 2px solid $light-gray;
	p{
		font-size: 12px;
	}
	&:last-child{
		border: 0px; 
	}
	b{
		display: block;
		margin-bottom: 10px;
	}
}
h4{
	color: $btn-primary-bg;
	font-size: 15px;
}
.filtro{
	padding:15px;
	.checkbox{
		margin-left:12px;
		border-left:1px #ddd dotted;
		padding-left:12px;
		padding-right:26px;
		&.first{
			padding-left:0px;
			border-left:0px;
		}
	}
}
.menu-table{
	width: 100%;
	.title,.preco,.descricaoprato{
		width: 80%;
		&.title{
			@extend .bold;
			font-size: 17px;
		}
		&.preco{
			width: 20%;
			text-align: right;
		}
		&.descricaoprato{
			padding-left: 20px; 
			margin-left: 20px;
			font-size: 13px;
		}
	}
}
.menus-area{
	border:1px #ddd solid;
	border-bottom:0px;
	.listagem{
		min-height: 550px;
	}
	.panel + .panel{
		margin-top:12px;
	}
	.panel{
		border-radius: 0px;
	}

	.nav-tabs > li > a{
		border-radius: 0px;
		margin: 0px;
		border-right:1px #ddd solid;
		font-size: 1.2em;
		@extend .bold;
	}
	.menu-area-content{
		padding:0px 15px;
	}
	.panel-heading {
		cursor: pointer;
		padding: 8px 12px;
		*{
			margin:0px;
		}
		font-size:0.9em;
	}
	.panel-title{
		@extend .bold;
		font-size: 1.6em;
	}
	.panel-body{
		padding:0px;
		table.table{
			margin:0px;
			tbody > tr > td{
				padding:8px 12px;
			}
		}
	}
	.price-btn{
	}
	.product-row{
		margin:0px;
		border-top:1px #ddd solid;
		position: relative;
		cursor: pointer;
	}
	.product-info{
		padding: 8px 15px 8px 15px;
		a{
			@extend .bold;
			font-size: 1.1em;
		}
	}
	.product-image{
		padding: 8px 0px 8px 12px;
		img{
			border:1px #f5f5f5 dotted;
		}
	}
	.preco{
		padding: 8px 15px 8px 12px;
		text-align: right;
		font-size: 1em;
		font-weight: bold;
		del{
			color:$state-warning-text;
			font-weight: normal;
		}
	}
}
// SIDE CART
.carrinho{
	margin: 0px;
	border: 1px solid $light-gray;
	border-right:0px;
	border-top:0px;
	position: relative;
	background-color: #fff;
	z-index: 1000;
	border-radius: 0px;
	box-shadow: 0px 0px 2px $light-gray;
	.title,.indicationlist,.itemselected,.itemlist,.pickup-delivery,.checkout{
		border-top: 1px solid $light-gray;
		padding: 10px 0 0 0;
		&.pickup-delivery{
			padding: 10px 0px;
		}
	}
	.checkout{
		padding: 15px;
	}
	.title{
		border-top: 0px;
		border-bottom: 1px solid $light-gray;
		padding: 0px 12px;
		margin: 0;
		height: 52px;
		line-height: 52px;
		font-size: 1.3em;
		@extend .bold;
		text-transform: uppercase;
		span.text{
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: inline-block;
			width: 100%;
		}
		span.icon{
			position: absolute;
			display: none;
			right: 15px;
			top:-15px;
			color: #333;
			background-color: #FFF;
			text-align: center;
			line-height: 52px;
			width: 52px;
			height: 52px;
			border-radius: 80px;
			box-shadow: 0 0 10px #000;
		}
	}
	.partner-warning{
		margin:0px;
	}
	-webkit-transition: top 666ms ease-in-out;
	-moz-transition: top 666ms ease-in-out;
	-ms-transition: top 666ms ease-in-out;
	-o-transition: top 666ms ease-in-out;
	transition: top 666ms ease-in-out;
}

// TABLE INSIDE SIDE CART
.cart-table{
	margin: 0px;
	border-bottom: 1px #CCC solid;
	.quantity{
		white-space: nowrap;
		padding-left:22px;
		padding-right:22px;
		text-align: center;
		button{
			padding:0px;
			width: 20px;
			line-height: 16px;
		}
	}
	.price{
		white-space: nowrap;
		text-align: right;
	}
	//background-color: lighten($light-gray,10%));
	thead tr th{
		background-color: lighten($light-gray,10%);
	}
	tfoot tr td{
		background-color: lighten($light-gray,10%);
		border:0px;
		padding-top:0px;
		padding-bottom:0px;
	}
	p{
		font-size: 0.8em;
		margin: 0px;
		color: #555;
	}
}

.itemlist{
	ul{ 
		padding:0;
		li{
			list-style: none;
		}
	}
}
.pickup-delivery{
	text-align: center;
	background-color: $btn-primary-color;
	.radiobloco{
		display: inline-block;
		width: 49%;
		label{
			display: inline;
			text-transform: uppercase;
			@extend .bold;
			color: $btn-primary-bg;
		}
	}
}
//  FIM DO CARRINHO
// AVALIAÇÕES
.avaliacoes{
	margin-top: 20px;
	.bloco{
		padding: 20px auto 0 auto;
		margin-bottom:10px; 
		ul {
			padding: 10px 0 0 0;
			li{
				list-style: none;
			}
		}
		span{
			float: right;
			margin-right: 0;
		}
		border-right: 1px solid $btn-primary-bg;
		&:after{
			display: block;
			content: "";
			width: 100%;
			height: 1px;
			background-color: orange;
			opacity: .2;
		}
		&:last-child{
			border-right: 0;
		}
	}
}
// AVALIAÇÕES END
// INFORMAÇÕES
.informacoes{
	margin-top:20px;
	ul{
		padding: 10px 0 0 0;
		li{
			list-style: none;
		}
	}
	img{
		margin-bottom: 5px;
	}
	.col-md-8{
		margin-bottom: 10px;
	}
}
.horario-table{
	width: 100%;
	td{
		text-align: center;
	}
}
// INFORMAÇÕES END
// REVEAL MODAL
.modal-content{
	overflow: hidden;
}
.modal-section{
	float: left;
	width: 40%;
	&.modal-product{
		width: 60%;
		border-right: 1px solid $shadow-color;
	}
	.section-content{
		padding: 15px;
		position: relative;
		.description{}
		.qty-spacer{
			float:right;
			width: 118px;
			height: 30px;
		}
		.section-quantity{
			background-color:#FFF;
			position: absolute;
			top:13px;
			right:15px;
			z-index: 100;
			b{
				color: #888;
			}
			input{
				width: 32px;
				text-align: center;
				-moz-appearance:textfield;
			}
		}
		img{
			width:100%;
		}
	}
}

.row.groups{
	.group{
		padding:0px;
	}
	.form-group{
		padding:0px 15px;
		border-left:1px #CCC solid;
	}
	label{
		display: block;
	}
	.valid{
		label{
			color: #498900;
			background-image: url(../images/tick.png);
			background-repeat: no-repeat;
			background-position: center right;
		}
	}
}
.group-item{
	border-top:1px #CCC dashed;
	margin-bottom:8px;
	position: relative;
	.quantity{
		float:right;
		display: block;
		width:48px;
		height:20px;
		text-align: right;
	}
	.remove{
		background-image: url(../images/trash.png);
		background-repeat: no-repeat;
		padding-left:22px;
		font-size: 0.86em;
		line-height: 26px;
		text-transform: lowercase;
		background-position: center left;
		display: block;
		cursor: pointer;
		&:hover{
			color:$brand-danger;
		}
	}
	.label{
		display: inline-block;
		white-space: normal;
		text-align: left;
	}
}

.variant-extras{
	ul{
		display: flex;
		flex-wrap: wrap;
		margin: 0px -15px;
		li{
			border-top:1px #CCC dashed;
			width: 33%;
			padding: 0px 15px 8px 15px;
		}
		label{
			font-weight: normal;
			overflow: hidden;
			display: block;
			padding:2px 0px;
			margin:0px;
			cursor: pointer;
		}
		.extra-price{
			color: #444;
			font-weight: bold;
		}
		.extra-title{
			display: block;
			padding-bottom: 3px;
		}
		li.active{
			label{
				color: #498900;
			}
			.label{
				background-color: #498900;
			}
		}
	}
}
.cart-modal{
	margin: 60px auto !important;
	.modal-title{
		background-color: $btn-primary-bg;
		color: $btn-primary-color;
		overflow: hidden;
		padding: 0px 12px;
		min-height: 52px;
		.orders{
			line-height: 52px;
			margin: 0px;
			padding: 0px;
		}
		table{
			width: 100%;
			min-height: 52px;
		}
		.price{
			font-size: 20px;
			line-height: 18px;
			@extend .bold;
			text-align: right;
			width: 80px;
			del{
				font-size:16px;
				color:#444444;
			}
			.pe{
				font-size:16px;
				color:#58220A;
			}
		}
		.title{
			text-transform: capitalize;
			margin: 0px;
			padding: 8px 0px;
			@extend .bold;
			font-size: 20px;
			line-height: 18px;
		}
	}
	&.is-service{
		width: 540px;
		.modal-title{
			background-color: #525A5B;
		}
		.modal-section.last{
			display: none;
		}
		.modal-section.modal-product{
			width: 100%;
			border:0px;
		}
	}
}
.variants{
	list-style: none;
	padding: 0;
	column-count: 3;
}
.instructions{
	float: left;
	width: 50%;
}
