@media (max-width: 767px) {
	.partner-logo{
		text-align: center;
		padding-top: 15px;
		img{
			margin: auto;
		}
	}
	.navbar-brand {
		padding: 0;
		img {
			width: 200px;
		}
	}
	.navbar-header {
		height: auto;
	}
	.navbar-default .navbar-nav {
		margin-top: 0;
	}
	// HOME PAGE -------------------------------------------
	.search-wrapper{
		margin-left: -40%;
		width: 80%;
		h1{
			margin-top: 0;
		}
	}
	.search-form{
		.form-group{
			width: 100%;
		}
		button.btn{
			width: 100%;
			img{
				width: auto;
			}
		}
	}
	.main-section{
		padding: 0px;
		h1, h2, h3{
			font-size: 26px;
			margin:0px;
		}
	}
	.step{
		border-bottom: 1px #7F6953 solid;
		padding-bottom:15px;
		margin-bottom: 15px;
		h3{
			margin-top: 5px;
		}
		&.down{
			margin-top: 0px;
		}
		&:after{
			display: none;
		}
		&.last{
			border-bottom-color: transparent;
		}
	}

	.partners-wrap{
		&:before{
			display: none;
		}
		&:after{
			display: none;
		}
	}
	.social-wrap{
		p{
			text-align: center;
		}
		.social{
			padding: 0;
			width: 90%;
			margin: 20px 5%; 
			li{
				width: 18.5%;
				img{
					width: 90%;
					margin: 0 5%;
				}
			}
		}
	}
	// MENU PAGE -------------------------------------------
	// AVALIAÇÕES
	.avaliacoes{
		.bloco{
			border-right: 0px;
		}
	}
	.logo img{ 
		width: 100%;
	}
	// FILTRO
	.listagem{
		.filtro{
			label{
				padding: 0;
				width: 100%;
			}
		}
	}
	// INFO RESTAURANTE
	.inforestaurante{
		text-align: center;
		h2{
			font-size: 25px;
		}
		.rate{
			padding-top: 0;
			padding-bottom: 20px;
		}
	}
	.infoboxes{
		border-right: 0px;
		width: 100%;
		text-align: center;
		border-bottom: 1px solid $btn-primary-bg;
	}
	.horario-table{
		td{
			padding: 0 2px 0 0;
			text-align: left;
		}
	}
	.menu-btn{
		border-radius: 0;
		padding: 10px 15px;
	}
	.carrinho{
		position: fixed;
		top: auto !important;
		bottom:0px;
		left:0px;
		right:0px;
		z-index: 1000;
		border:0px;
		.title{
			background-color: $btn-primary-bg;
			color: #FFF;
			border-bottom:0px;
			padding-right: 84px;
			cursor: pointer;
			span.icon{
				display: block;
				overflow: hidden;
				.ic{
					display: inline-block;
					margin-top: -50px;

					-webkit-transition: all 333ms ease-in-out;
					-moz-transition: all 333ms ease-in-out;
					-ms-transition: all 333ms ease-in-out;
					-o-transition: all 333ms ease-in-out;
					transition: all 333ms ease-in-out;
					span{
						display: block;
						line-height: 50px;
					}
				}
			}
		}
		&.animate{
			.title{
				span.icon{
					background-color: #5CB85C !important;
					top: -25px;
					color: #FFF;
					-webkit-transition: all 333ms ease-in-out;
					-moz-transition: all 333ms ease-in-out;
					-ms-transition: all 333ms ease-in-out;
					-o-transition: all 333ms ease-in-out;
					transition: all 333ms ease-in-out;
					.ic{
						margin-top: -104px !important;
					}
				}
			}
		}
		.cart-container{
			overflow: auto;
			height:0px;
		}
		.dontfollow{
			display: none;
		}
	}
	.menus-area{
		border: 0px;
		.nav{
			display: none;
		}
		.menu-area-content{
			padding:0px;
		}
	}
	.open-cart{
		overflow: hidden;
	}
	.open-cart .carrinho{
		top: 0px !important;
		.title{
			position: absolute;
			bottom: 0px;
			left:0px;
			right:0px;
			background-color: #333;

			span.icon{
				background-color: #ddd;
				.ic{
					margin-top:0px;
				}
			}
		}
		.cart-container{
			position: absolute;
			top:0px;
			bottom:52px;
			left:0px;
			right:0px;
			height: auto;
		}
	}
	.works-wrap{
		&:after, &:before{
			display: none;
		}

	}
	.block.restaurants{
		margin: 0 0 20px 0;
		.filtro{
			ul{ //small screen checkbox list a laranja no restaurants.html
				width: 100%;
				li{
					width: 100%;
					display: block;
					background: $btn-primary-bg;
					padding-top: 10px;
					&:first-child{
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}
					&:last-child{
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
					}
					label{
						width: 100%;
						color: $btn-primary-color;
					}
				}
			}
		}
		.infoboxes{
			border: none;
			text-align: left;
		}
		.panel-heading{
			img{
				width: 60%;
				margin: 0 20%;
			}
			text-align: center;
		}
	}
	.links{
		float: none;
		a{
			display: block;
			width: 100%;
			text-align: center;
			margin: 0;
		}
	}
	small{
		text-align: center;
		width: 100%;
		margin-bottom: 20px;
	}
	.main-footer{
		text-align:center;
		padding-bottom: 72px;
	}
	//--------------------------------
	// POP-UP
	.cart-modal{
		margin: 10px auto !important;
	}
	.modal-section,
	.modal-section.modal-cart,
	.modal-section.modal-product,
	textarea{
		width: 100%;
	}
	.instructions{
		width: 100%;
	}
	.modal-section.last{
		display: none;
	}
	.variants{
		-moz-column-count: 1;
	}
	.admin_tools{
		bottom: auto;
		top:0px;
	}
}