@media (min-width: 768px) {
	.side-menu-wrap{
		position: absolute;
		left:0px;
		top: 0px;
		width:0px;
		height:0px;
		.menu-btn{
			line-height: inherit;
			color: inherit;
			font-weight: normal;

			height: 38px;
			width:180px;
			text-align: center;
			@include rotate(-90deg);
			@include border-top-radius(6px);
			@include box-shadow(0 0px 1px $shadow-color);
			display: block;
			border-bottom: none;
			padding: 0px;
			text-transform: uppercase;
			position: absolute;
			left:-95px;
			top:93px;
			z-index: 420;
			-webkit-transition: height 666ms ease-out, left 666ms ease-out, overflow 666ms ease-out, background 666ms ease-out;
			-moz-transition: height 666ms ease-out, left 666ms ease-out, overflow 666ms ease-out, background 666ms ease-out;
			-ms-transition: height 666ms ease-out, left 666ms ease-out, overflow 666ms ease-out, background 666ms ease-out;
			-o-transition: height 666ms ease-out, left 666ms ease-out, overflow 666ms ease-out, background 666ms ease-out;
			transition: height 666ms ease-out, left 666ms ease-out, overflow 666ms ease-out, background 666ms ease-out;
		}

		-webkit-transition: top 666ms ease-in-out;
		-moz-transition: top 666ms ease-in-out;
		-ms-transition: top 666ms ease-in-out;
		-o-transition: top 666ms ease-in-out;
		transition: top 666ms ease-in-out;
	}
	.side-menu-wrap.wrapanim{
		opacity: 0;
		.menu-btn{
			height: 0px;
			left:-73px;
			overflow: hidden;
			background-color: #58220A;
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-left{
		float: right !important;
	}
	.current_zone{
		text-align: right;
	}
	.navbar-collapse{
		margin-top: 15px !important;
	}
	.search-wrapper{
		margin-left: -40%;
		width: 80%;
		.search-form .btn{
			padding: 1% 2% 1% 2%;
		}
	}
	.inforestaurante{
		.infoboxes{
			padding: 0 5px;
			h4{
				font-size: 12px;
			}
			p{
				font-size: 10px;
			}
		}
	}
	.highlights-wrap{
		padding: 50px 0 100px 0;
	}
	.ingredientes{
		&.alho,&.colher,&.louro{
			width: 15%;
		}
		&.louro{
			margin-top: -40px;
		}
	}
	.fixed-pos{
		width: 281px;
	}
	.works-wrap{
		.step{
			.arrow{
				width: 30%;
			}
		}
	}
	.instructions{
		width: 100%;
	}
	.block.restaurants{
		margin: 0 0 20px 0;
		.infoboxes{
			text-align: left;
		    input[type="text"]{
				width: 70%;
			}
			input[type="submit"]{
				width: 28%;
			}
		}
		.panel-heading{
			.medium-inline{
				li{
					display: inline-block;
					width: 24%;
					input[type="submit"]{
						width: 100%;
					}
				}
			}
		}
	}
	footer{
		text-align: center;
	}
}